import cn from 'classnames';
import { GridSmall } from '@components/UICirclesAnimation/BackgroundElements';
import DynamicClientLogoMarquee from '@components/Marquee/DynamicClientLogoMarquee';
import TextRenderer from '@components/TextRenderer';
import Actions from '@components/Actions';
import { Packages } from './illustrations/Packages';
import { Safeguard } from './illustrations/Safeguard';
import { Distribution } from './illustrations/Distribution';

import * as styles from './ProductHero.module.css';
import * as textStyles from '@styles/textStyles.module.css';

export const ProductHero = ({
  eyebrow,
  heading,
  subline,
  illustration,
  actions,
  clientLogos = [],
  theme = 'dark',
}) => {
  return (
    <div
      className={cn(styles.productHero, {
        [styles.illustrationPackages]: illustration === 'packages',
        [styles.illustrationSafeguard]: illustration === 'safeguard',
        [styles.illustrationDistribution]: illustration === 'distribution',
      })}
    >
      <div className={styles.top}>
        <div className={styles.foreground}>
          <div className={cn(styles.wrapper)}>
            <div className={cn(styles.wrapperInner)}>
              <div className={cn(styles.heroText)}>
                {eyebrow && (
                  <TextRenderer
                    className={cn(textStyles.eyebrow, styles.eyebrow)}
                    text={eyebrow}
                    as={'h3'}
                  />
                )}
                {heading && (
                  <TextRenderer
                    className={cn(textStyles.headlineXL, styles.heading)}
                    text={heading}
                    as={'h1'}
                  />
                )}
                {subline && (
                  <TextRenderer
                    className={cn(textStyles.headlineS, styles.subline)}
                    text={subline}
                    as={'h2'}
                  />
                )}
              </div>
              {actions && (
                <div className={cn(styles.cta)}>
                  <Actions actions={actions} theme={theme} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={cn(styles.background)}>
          <div className={cn(styles.grid)}>
            <GridSmall />
          </div>
          <div className={cn(styles.illustration)}>
            {illustration === 'packages' && <Packages />}
            {illustration === 'safeguard' && <Safeguard />}
            {illustration === 'distribution' && <Distribution />}
          </div>
          <div className={cn(styles.topVignette)}></div>
          <div className={cn(styles.leftVignette)}></div>
          <div className={cn(styles.bottomVignette)}></div>
          <div className={cn(styles.rightVignette)}></div>
        </div>
      </div>
      {clientLogos && clientLogos.length > 0 && (
        <DynamicClientLogoMarquee
          clientLogos={clientLogos}
          invertColorsOnMobile={true}
        />
      )}
    </div>
  );
};
