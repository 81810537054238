export const Grid = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1921"
      height="961"
      viewBox="0 0 1921 961"
      fill="none"
    >
      <path
        d="M0.500084 0L0.500045 961M80.5001 0L80.5001 961M160.5 0L160.5 961M240.5 0L240.5 961M320.5 0L320.5 961M400.5 0L400.5 961M480.5 0L480.5 961M560.5 0L560.5 961M640.501 0L640.5 961M720.501 0L720.501 961M800.501 0L800.501 961M880.501 0L880.501 961M960.501 0L960.501 961M1040.5 0L1040.5 961M1120.5 0L1120.5 961M1200.5 0L1200.5 961M1280.5 0L1280.5 961M1360.5 0L1360.5 961M1440.5 0L1440.5 961M1520.5 0L1520.5 961M1600.5 0L1600.5 961M1680.5 0L1680.5 961M1760.5 0L1760.5 961M1840.5 0L1840.5 961M1920.5 0L1920.5 961M4.1963e-05 960.5H1920M3.84661e-05 880.5H1920M3.49691e-05 800.5H1920M3.14722e-05 720.5H1920M2.79753e-05 640.5L1920 640.5M2.44784e-05 560.5H1920M2.09815e-05 480.499H1920M1.74846e-05 400.499H1920M1.39877e-05 320.499H1920M1.04907e-05 240.499H1920M6.99383e-06 160.499H1920M3.49691e-06 80.4991H1920M0 0.498993H1920"
        stroke="var(--brand-color-grey-8, #26465B)"
        strokeDasharray="2 2"
      />
    </svg>
  );
};

export const GridSmall = () => {
  return (
    <svg
      width="1920"
      height="1123"
      viewBox="0 0 1920 1123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.5"
        y1="2.3677e-08"
        x2="0.499955"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="40.5"
        y1="2.3677e-08"
        x2="40.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="80.5"
        y1="2.3677e-08"
        x2="80.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="120.5"
        y1="2.3677e-08"
        x2="120.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="160.5"
        y1="2.3677e-08"
        x2="160.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="200.5"
        y1="2.3677e-08"
        x2="200.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="240.5"
        y1="2.3677e-08"
        x2="240.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="280.5"
        y1="2.3677e-08"
        x2="280.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="320.5"
        y1="2.3677e-08"
        x2="320.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="360.5"
        y1="2.3677e-08"
        x2="360.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="400.5"
        y1="2.3677e-08"
        x2="400.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="440.5"
        y1="2.3677e-08"
        x2="440.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="480.5"
        y1="2.3677e-08"
        x2="480.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="520.5"
        y1="2.3677e-08"
        x2="520.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="560.5"
        y1="2.3677e-08"
        x2="560.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="600.5"
        y1="2.3677e-08"
        x2="600.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="640.5"
        y1="2.3677e-08"
        x2="640.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="680.5"
        y1="2.3677e-08"
        x2="680.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="720.5"
        y1="2.3677e-08"
        x2="720.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="760.5"
        y1="2.3677e-08"
        x2="760.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="800.5"
        y1="2.3677e-08"
        x2="800.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="840.5"
        y1="2.3677e-08"
        x2="840.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="880.5"
        y1="2.3677e-08"
        x2="880.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="920.5"
        y1="2.3677e-08"
        x2="920.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="960.5"
        y1="2.3677e-08"
        x2="960.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1000.5"
        y1="2.3677e-08"
        x2="1000.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1040.5"
        y1="2.3677e-08"
        x2="1040.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1080.5"
        y1="2.3677e-08"
        x2="1080.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1120.5"
        y1="2.3677e-08"
        x2="1120.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1160.5"
        y1="2.3677e-08"
        x2="1160.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1200.5"
        y1="2.3677e-08"
        x2="1200.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1240.5"
        y1="2.3677e-08"
        x2="1240.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1280.5"
        y1="2.3677e-08"
        x2="1280.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1320.5"
        y1="2.3677e-08"
        x2="1320.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1360.5"
        y1="2.3677e-08"
        x2="1360.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1400.5"
        y1="2.3677e-08"
        x2="1400.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1440.5"
        y1="2.3677e-08"
        x2="1440.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1480.5"
        y1="2.3677e-08"
        x2="1480.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1520.5"
        y1="2.3677e-08"
        x2="1520.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1560.5"
        y1="2.3677e-08"
        x2="1560.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1600.5"
        y1="2.3677e-08"
        x2="1600.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1640.5"
        y1="2.3677e-08"
        x2="1640.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1680.5"
        y1="2.3677e-08"
        x2="1680.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1720.5"
        y1="2.3677e-08"
        x2="1720.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1760.5"
        y1="2.3677e-08"
        x2="1760.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1800.5"
        y1="2.3677e-08"
        x2="1800.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1840.5"
        y1="2.3677e-08"
        x2="1840.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="1880.5"
        y1="2.3677e-08"
        x2="1880.5"
        y2="1123"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="1122.5"
        x2="1948"
        y2="1122.5"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="1082.5"
        x2="1948"
        y2="1082.5"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="1042.5"
        x2="1948"
        y2="1042.5"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="1002.5"
        x2="1948"
        y2="1002.5"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="962.499"
        x2="1948"
        y2="962.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="922.499"
        x2="1948"
        y2="922.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="882.499"
        x2="1948"
        y2="882.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="842.499"
        x2="1948"
        y2="842.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="802.499"
        x2="1948"
        y2="802.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="762.499"
        x2="1948"
        y2="762.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="722.499"
        x2="1948"
        y2="722.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="682.499"
        x2="1948"
        y2="682.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="642.499"
        x2="1948"
        y2="642.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="602.499"
        x2="1948"
        y2="602.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="562.499"
        x2="1948"
        y2="562.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="522.499"
        x2="1948"
        y2="522.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="482.499"
        x2="1948"
        y2="482.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="442.499"
        x2="1948"
        y2="442.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="402.499"
        x2="1948"
        y2="402.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="362.499"
        x2="1948"
        y2="362.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="322.499"
        x2="1948"
        y2="322.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="282.499"
        x2="1948"
        y2="282.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="242.499"
        x2="1948"
        y2="242.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="202.499"
        x2="1948"
        y2="202.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="162.499"
        x2="1948"
        y2="162.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="122.499"
        x2="1948"
        y2="122.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="82.499"
        x2="1948"
        y2="82.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
      <line
        x1="10"
        y1="42.499"
        x2="1948"
        y2="42.499"
        stroke="var(--brand-color-grey-9)"
        strokeDasharray="2 2"
      />
    </svg>
  );
};

export const LeftPathways = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="642"
      height="962"
      viewBox="0 0 642 962"
      fill="none"
    >
      <path
        d="M1 1L241 1.00007V81.0001M241 322V480M401 641V481L481 401M241 721L241 961M241 961L401 801.001M241 961L1 961M321 81.0001H1M321 81.0001L81 321M321 81.0001L401 161M401 161L1 161M401 161L81 481L241 641M401 161V241L481 321M481.001 241H2M481 321H1M481 321V401.064M481 721L561 641M481 721L1 721M481 721L321 561.001M561 641L1 641M561 641L241 321M480.999 401H1M481 561.001L561 481.001L321 241M641 481H1M482 561H1M480.999 241L320.999 401M241 241L81 81M321 561.001L81.0015 801M321 561.001V721.001L401 801.001M82 641L242 481M401 801.001L1 801M241 881L161 801M161 881L241 801M81.5 160.5L240.5 1.5M321 881H1"
        stroke="var(--brand-color-salmon-5, #ED8565)"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const RightPathways = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="642"
      height="962"
      viewBox="0 0 642 962"
      fill="none"
    >
      <path
        d="M641 961H480.5L80.5 561L80.5 401L480.5 1L641.002 1.00006M400.5 881H641M320.5 801H641M240.5 721H641M160.5 641H641M81 561L641 561M1 481H641M81 401L641 401M160.5 321H641M240.5 241H641M321 161L641 161M400.5 81.0001H641"
        stroke="var(--brand-color-purple-6, #915EFF)"
        strokeLinecap="round"
      />
    </svg>
  );
};
