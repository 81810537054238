import cn from 'classnames';
import * as styles from './UIElements.module.css';
import { useEffect, useState, useRef, forwardRef } from 'react';

export const UIBrowser = ({ inView }) => {
  return (
    <div className={cn(styles.uiBrowser, { [styles.inView]: inView })}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="685"
        height="32"
        viewBox="0 0 685 32"
        fill="none"
      >
        <path
          d="M38 15.5C38 11.9101 40.9101 9 44.5 9C48.0899 9 51 11.9101 51 15.5C51 19.0899 48.0899 22 44.5 22C40.9101 22 38 19.0899 38 15.5Z"
          fill="var(--base-color-grey-1000, #031c2b)"
        />
        <path
          d="M38 15.5C38 11.9101 40.9101 9 44.5 9C48.0899 9 51 11.9101 51 15.5C51 19.0899 48.0899 22 44.5 22C40.9101 22 38 19.0899 38 15.5Z"
          fill="#4E6978"
          fillOpacity="0.2"
        />
        <path
          d="M19 15.5C19 11.9101 21.9101 9 25.5 9C29.0899 9 32 11.9101 32 15.5C32 19.0899 29.0899 22 25.5 22C21.9101 22 19 19.0899 19 15.5Z"
          fill="var(--base-color-grey-1000, #031c2b)"
        />
        <path
          d="M19 15.5C19 11.9101 21.9101 9 25.5 9C29.0899 9 32 11.9101 32 15.5C32 19.0899 29.0899 22 25.5 22C21.9101 22 19 19.0899 19 15.5Z"
          fill="#4E6978"
          fillOpacity="0.2"
        />
        <path
          d="M0 15.5C0 11.9101 2.91015 9 6.5 9C10.0899 9 13 11.9101 13 15.5C13 19.0899 10.0899 22 6.5 22C2.91015 22 0 19.0899 0 15.5Z"
          fill="var(--base-color-grey-1000, #031c2b)"
        />
        <path
          d="M0 15.5C0 11.9101 2.91015 9 6.5 9C10.0899 9 13 11.9101 13 15.5C13 19.0899 10.0899 22 6.5 22C2.91015 22 0 19.0899 0 15.5Z"
          fill="#4E6978"
          fillOpacity="0.2"
        />
        <path
          d="M65 6C65 2.68629 67.6863 0 71 0H679C682.314 0 685 2.68629 685 6V26C685 29.3137 682.314 32 679 32H71C67.6863 32 65 29.3137 65 26V6Z"
          fill="var(--base-color-grey-1000, #031c2b)"
        />
        <path
          d="M65 6C65 2.68629 67.6863 0 71 0H679C682.314 0 685 2.68629 685 6V26C685 29.3137 682.314 32 679 32H71C67.6863 32 65 29.3137 65 26V6Z"
          fill="#4E6978"
          fillOpacity="0.2"
        />
        <path
          d="M38.5 15.5C38.5 12.1863 41.1863 9.5 44.5 9.5C47.8137 9.5 50.5 12.1863 50.5 15.5C50.5 18.8137 47.8137 21.5 44.5 21.5C41.1863 21.5 38.5 18.8137 38.5 15.5ZM19.5 15.5C19.5 12.1863 22.1863 9.5 25.5 9.5C28.8137 9.5 31.5 12.1863 31.5 15.5C31.5 18.8137 28.8137 21.5 25.5 21.5C22.1863 21.5 19.5 18.8137 19.5 15.5ZM0.5 15.5C0.5 12.1863 3.18629 9.5 6.5 9.5C9.81371 9.5 12.5 12.1863 12.5 15.5C12.5 18.8137 9.81371 21.5 6.5 21.5C3.18629 21.5 0.5 18.8137 0.5 15.5ZM65.5 6C65.5 2.96243 67.9624 0.5 71 0.5H679C682.038 0.5 684.5 2.96243 684.5 6V26C684.5 29.0376 682.038 31.5 679 31.5H71C67.9624 31.5 65.5 29.0376 65.5 26V6Z"
          stroke="#4E6978"
          strokeOpacity="0.3"
        />
      </svg>
    </div>
  );
};

export const UIModal = ({ inView }) => {
  return (
    <div className={cn(styles.uiModal, { [styles.inView]: inView })}>
      <svg
        width="720"
        height="560"
        viewBox="0 0 720 560"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 0C1.79086 0 0 1.79085 0 3.99999V556C0 558.209 1.79087 560 4.00001 560H716C718.209 560 720 558.209 720 556V4C720 1.79086 718.209 0 716 0H4ZM48 240C43.5817 240 40 243.582 40 248V312C40 316.418 43.5817 320 48 320H672C676.418 320 680 316.418 680 312V248C680 243.582 676.418 240 672 240H48Z"
          fill="var(--brand-color-black, #0a1b2a)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 0C1.79086 0 0 1.79085 0 3.99999V556C0 558.209 1.79087 560 4.00001 560H716C718.209 560 720 558.209 720 556V4C720 1.79086 718.209 0 716 0H4ZM48 240C43.5817 240 40 243.582 40 248V312C40 316.418 43.5817 320 48 320H672C676.418 320 680 316.418 680 312V248C680 243.582 676.418 240 672 240H48Z"
          fill="#4E6978"
          fillOpacity="0.3"
        />
        <path
          d="M494 30C494 26.6863 496.686 24 500 24H646C649.314 24 652 26.6863 652 30V50C652 53.3137 649.314 56 646 56H500C496.686 56 494 53.3137 494 50V30Z"
          fill="#4E6978"
          fillOpacity="0.1"
        />
        <path
          d="M500 24.5H646C649.038 24.5 651.5 26.9624 651.5 30V50C651.5 53.0376 649.038 55.5 646 55.5H500C496.962 55.5 494.5 53.0376 494.5 50V30C494.5 26.9624 496.962 24.5 500 24.5Z"
          stroke="#7A8B95"
          strokeOpacity="0.3"
        />
        <path
          d="M506 44.0999L509.308 40.7918L510.058 41.5413L506.749 44.8494L506 44.0999Z"
          fill="var(--base-color-grey-600, #5f7886)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M512.058 41.2416C513.489 41.2416 514.649 40.0816 514.649 38.6507C514.649 37.2198 513.489 36.0599 512.058 36.0599C510.627 36.0599 509.468 37.2198 509.468 38.6507C509.468 40.0816 510.627 41.2416 512.058 41.2416ZM512.058 42.3015C514.075 42.3015 515.709 40.667 515.709 38.6507C515.709 36.6345 514.075 35 512.058 35C510.042 35 508.408 36.6345 508.408 38.6507C508.408 40.667 510.042 42.3015 512.058 42.3015Z"
          fill="var(--base-color-grey-600, #5f7886)"
        />
        <path
          d="M664 30C664 26.6863 666.686 24 670 24H690C693.314 24 696 26.6863 696 30V50C696 53.3137 693.314 56 690 56H670C666.686 56 664 53.3137 664 50V30Z"
          fill="#4E6978"
          fillOpacity="0.1"
        />
        <path
          d="M670 24.5H690C693.038 24.5 695.5 26.9624 695.5 30V50C695.5 53.0376 693.038 55.5 690 55.5H670C666.962 55.5 664.5 53.0376 664.5 50V30C664.5 26.9624 666.962 24.5 670 24.5Z"
          stroke="#7A8B95"
          strokeOpacity="0.3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M677.345 37.677C677.345 36.21 678.535 35.0208 680.001 35.0208C681.468 35.0208 682.658 36.21 682.658 37.677C682.658 39.144 681.468 40.3332 680.001 40.3332C678.535 40.3332 677.345 39.144 677.345 37.677ZM676.345 37.677C676.345 35.6577 677.982 34.0208 680.001 34.0208C682.021 34.0208 683.658 35.6577 683.658 37.677C683.658 39.6962 682.021 41.3332 680.001 41.3332C677.982 41.3332 676.345 39.6962 676.345 37.677ZM676.944 43.4669L674.861 45.8108L674.113 45.1463L676.495 42.4669H683.508L685.89 45.1463L685.142 45.8108L683.059 43.4669H676.944Z"
          fill="var(--base-color-grey-600, #5f7886)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54 38.4447V41.5558L41.5556 54.0002H38.4444L26 41.5558V38.4447L38.4444 26.0002H41.5556L54 38.4447ZM39.9998 47.3047C44.0337 47.3047 47.3038 44.0346 47.3038 40.0007C47.3038 35.9668 44.0337 32.6966 39.9998 32.6966C35.9659 32.6966 32.6958 35.9668 32.6958 40.0007C32.6958 44.0346 35.9659 47.3047 39.9998 47.3047Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1C2.34314 1 1 2.34314 1 3.99999V556C1 557.657 2.34315 559 4.00001 559H716C717.657 559 719 557.657 719 556V4C719 2.34315 717.657 1 716 1H4ZM0 3.99999C0 1.79085 1.79086 0 4 0H716C718.209 0 720 1.79086 720 4V556C720 558.209 718.209 560 716 560H4.00001C1.79087 560 0 558.209 0 556V3.99999Z"
          fill="#4E6978"
          fillOpacity="0.3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M720 120H0V119H720V120Z"
          fill="#7A8B95"
          fillOpacity="0.3"
        />
      </svg>
    </div>
  );
};

export const UIScanner = ({ inView }) => {
  return (
    <div className={cn(styles.uiScanner, { [styles.inView]: inView })}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="641"
        height="80"
        viewBox="0 0 641 80"
        fill="none"
      >
        <path
          d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H632.5C636.918 0 640.5 3.58172 640.5 8V72C640.5 76.4183 636.918 80 632.5 80H8.49999C4.08171 80 0.5 76.4183 0.5 72V8Z"
          fill="#1A3342"
        />
        <path
          d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H632.5C636.918 0 640.5 3.58172 640.5 8V72C640.5 76.4183 636.918 80 632.5 80H8.49999C4.08171 80 0.5 76.4183 0.5 72V8Z"
          fill="var(--base-color-grey-1000, #031c2b)"
          fillOpacity="0.3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M632.5 1H8.5C4.63401 1 1.5 4.13401 1.5 8V72C1.5 75.866 4.634 79 8.49999 79H632.5C636.366 79 639.5 75.866 639.5 72V8C639.5 4.13401 636.366 1 632.5 1ZM8.5 0C4.08172 0 0.5 3.58172 0.5 8V72C0.5 76.4183 4.08171 80 8.49999 80H632.5C636.918 80 640.5 76.4183 640.5 72V8C640.5 3.58172 636.918 0 632.5 0H8.5Z"
          fill="#7A8B95"
          fillOpacity="0.3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 40H640.5V41H0.5V40Z"
          fill="var(--brand-color-green-5, #1bb694)"
        />
      </svg>
    </div>
  );
};

export const UICardOne = ({ inView, caption, uiCardsActions }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(uiCardsActions.cardsStates[0].active);
  }, [uiCardsActions]);

  return (
    <div
      className={cn(
        styles.uiCard,
        styles.uiCardOne,
        { [styles.inView]: inView },
        { [styles.active]: active },
      )}
    >
      <div className={cn(styles.uiCardCaption)}>{caption}</div>
      <svg
        width="220"
        height="100"
        viewBox="0 0 220 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V92C220 96.4183 216.418 100 212 100H8.00001C3.58173 100 0 96.4183 0 92V8Z"
            fill="#1A3342"
          />
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V92C220 96.4183 216.418 100 212 100H8.00001C3.58173 100 0 96.4183 0 92V8Z"
            fill="#4E6978"
            fillOpacity="0.3"
          />
          <path
            className={cn(styles.uiCardStroke, {
              [styles.uiCardStrokeActive]: active,
            })}
            d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H212C216.142 0.5 219.5 3.85786 219.5 8V92C219.5 96.1421 216.142 99.5 212 99.5H8.00001C3.85787 99.5 0.5 96.1421 0.5 92V8Z"
            stroke="#7A8B95"
            strokeOpacity="0.3"
          />
        </g>
        <g
          className={cn(styles.uiCardIcons, {
            [styles.uiCardIconsActive]: active,
          })}
        >
          <g className={cn(styles.uiCardIconGrey)}>
            <rect
              x="16"
              y="29.75"
              width="24"
              height="24"
              fill="none"
              stroke="transparent"
              strokeWidth="1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.9299 35.0059H24.4587L22.648 36.8165V39.8566H33.3554V36.8165L31.5447 35.0059H30.0391H28.9299ZM34.8554 39.8566V36.1952L32.166 33.5059H30.0391H28.9299H23.8373L21.148 36.1952V39.8566H18.1797V41.3566H37.8235V39.8566H34.8554ZM28.9299 46.9931H24.4586L22.6479 45.1824V43.0234H21.1479V45.8038L23.8373 48.4931H28.9299H29.5648H32.166L34.8553 45.8038V43.0234H33.3553V45.1824L31.5447 46.9931H29.5648H28.9299Z"
              fill="var(--brand-color-grey-6, #768fa2)"
            />
          </g>
          <g className={cn(styles.uiCardIconGreen)}>
            <rect
              x="16"
              y="53"
              width="24"
              height="24"
              fill="none"
              stroke="transparent"
              strokeWidth="1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7695 62.8797L25.878 56.7712L30.1185 56.7712L36.227 62.8797V67.1203L30.1185 73.2287H25.878L19.7695 67.1203V62.8797ZM21.2695 63.501V66.4989L26.4993 71.7287H29.4972L34.727 66.4989V63.501L29.4972 58.2712L26.4993 58.2712L21.2695 63.501Z"
              fill="var(--brand-color-green-5, #1bb694)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.5232 62.9386L28.148 67.7584H26.7486L24.4733 65.2638L25.5177 64.253L27.3469 66.2584H27.4219L30.3492 62.0782L31.5232 62.9386Z"
              fill="var(--brand-color-green-5, #1bb694)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export const UICardTwo = ({ inView, caption, uiCardsActions }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(uiCardsActions.cardsStates[1].active);
  }, [uiCardsActions]);

  return (
    <div
      className={cn(
        styles.uiCard,
        styles.uiCardTwo,
        { [styles.inView]: inView },
        { [styles.active]: active },
      )}
    >
      <div className={cn(styles.uiCardCaption)}>{caption}</div>
      <svg
        width="220"
        height="100"
        viewBox="0 0 220 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V92C220 96.4183 216.418 100 212 100H8.00001C3.58173 100 0 96.4183 0 92V8Z"
            fill="#1A3342"
          />
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V92C220 96.4183 216.418 100 212 100H8.00001C3.58173 100 0 96.4183 0 92V8Z"
            fill="#4E6978"
            fillOpacity="0.3"
          />
          <path
            className={cn(styles.uiCardStroke, {
              [styles.uiCardStrokeActive]: active,
            })}
            d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H212C216.142 0.5 219.5 3.85786 219.5 8V92C219.5 96.1421 216.142 99.5 212 99.5H8.00001C3.85787 99.5 0.5 96.1421 0.5 92V8Z"
            stroke="#7A8B95"
            strokeOpacity="0.3"
          />
        </g>

        <g
          className={cn(styles.uiCardIcons, {
            [styles.uiCardIconsActive]: active,
          })}
        >
          <g className={cn(styles.uiCardIconGrey)}>
            <rect
              x="16.1"
              y="29.25"
              width="24"
              height="24"
              fill="none"
              stroke="transparent"
              strokeWidth="1"
            />
            <path
              d="M23.0423 32.313H32.4329L34.2175 34.0976V42.799H32.7175V34.7189L31.8116 33.813L23.6636 33.813L22.7577 34.7189V46.6802L23.6636 47.5861H28.9455V49.0861H23.0423L21.2577 47.3015V34.0976L23.0423 32.313Z"
              fill="var(--brand-color-grey-6, #768fa2)"
            />
            <path
              d="M24.2905 38.3745H31.2025V37.0245H24.2905V38.3745Z"
              fill="var(--brand-color-grey-6, #768fa2)"
            />
            <path
              d="M31.2025 40.0245V41.3745H24.2725V40.0245H31.2025Z"
              fill="var(--brand-color-grey-6, #768fa2)"
            />
            <path
              d="M28.9992 44.3745H24.2725V43.0245H28.9992V44.3745Z"
              fill="var(--brand-color-grey-6, #768fa2)"
            />
            <path
              d="M34.9375 50.187V43.9995H30.4375V50.187H31.0987L32.6875 49.1409L34.2763 50.187H34.9375Z"
              fill="var(--brand-color-grey-6, #768fa2)"
            />
          </g>
          <g className={cn(styles.uiCardIconGreen)}>
            <rect
              x="16"
              y="53"
              width="24"
              height="24"
              fill="none"
              stroke="transparent"
              strokeWidth="1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7695 62.8797L25.878 56.7712L30.1185 56.7712L36.227 62.8797V67.1203L30.1185 73.2287H25.878L19.7695 67.1203V62.8797ZM21.2695 63.501V66.4989L26.4993 71.7287H29.4972L34.727 66.4989V63.501L29.4972 58.2712L26.4993 58.2712L21.2695 63.501Z"
              fill="var(--brand-color-green-5, #1bb694)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.5232 62.9386L28.148 67.7584H26.7486L24.4733 65.2638L25.5177 64.253L27.3469 66.2584H27.4219L30.3492 62.0782L31.5232 62.9386Z"
              fill="var(--brand-color-green-5, #1bb694)"
            />
          </g>
        </g>
        <defs>
          <clipPath
            id="bgblur_0_1886_4378_clip_path"
            transform="translate(10 10)"
          >
            <path d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V92C220 96.4183 216.418 100 212 100H8.00001C3.58173 100 0 96.4183 0 92V8Z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const UICardThree = ({ inView, caption, uiCardsActions }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(uiCardsActions.cardsStates[2].active);
  }, [uiCardsActions]);

  return (
    <div
      className={cn(
        styles.uiCard,
        styles.uiCardThree,
        { [styles.inView]: inView },
        { [styles.active]: active },
      )}
    >
      <div className={cn(styles.uiCardCaption)}>{caption}</div>
      <svg
        width="220"
        height="100"
        viewBox="0 0 220 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V92C220 96.4183 216.418 100 212 100H8.00001C3.58173 100 0 96.4183 0 92V8Z"
            fill="#1A3342"
          />
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V92C220 96.4183 216.418 100 212 100H8.00001C3.58173 100 0 96.4183 0 92V8Z"
            fill="#4E6978"
            fillOpacity="0.3"
          />
          <path
            className={cn(styles.uiCardStroke, {
              [styles.uiCardStrokeActive]: active,
            })}
            d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H212C216.142 0.5 219.5 3.85786 219.5 8V92C219.5 96.1421 216.142 99.5 212 99.5H8.00001C3.85787 99.5 0.5 96.1421 0.5 92V8Z"
            stroke="#7A8B95"
            strokeOpacity="0.3"
          />
        </g>

        <g
          className={cn(styles.uiCardIcons, {
            [styles.uiCardIconsActive]: active,
          })}
        >
          <g className={cn(styles.uiCardIconGrey)}>
            <rect
              x="16"
              y="29.09"
              width="24"
              height="24"
              fill="none"
              stroke="transparent"
              strokeWidth="1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.2541 32.9426V43.5701L23.0329 39.3489L21.9723 40.4096L26.7708 45.2081H29.2374L34.0359 40.4096L32.9753 39.3489L28.7541 43.5701V32.9426H27.2541ZM23.4185 47.7311L20.2929 44.2153L19.1719 45.212L22.745 49.2311H26.7188H29.2895H33.2633L36.8364 45.212L35.7154 44.2153L32.5898 47.7311H29.2895H26.7188H23.4185Z"
              fill="var(--brand-color-grey-6, #768fa2)"
            />
          </g>
          <g className={cn(styles.uiCardIconGreen)}>
            <rect
              x="16"
              y="53"
              width="24"
              height="24"
              fill="none"
              stroke="transparent"
              strokeWidth="1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7695 62.8797L25.878 56.7712L30.1185 56.7712L36.227 62.8797V67.1203L30.1185 73.2287H25.878L19.7695 67.1203V62.8797ZM21.2695 63.501V66.4989L26.4993 71.7287H29.4972L34.727 66.4989V63.501L29.4972 58.2712L26.4993 58.2712L21.2695 63.501Z"
              fill="var(--brand-color-green-5, #1bb694)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.5232 62.9386L28.148 67.7584H26.7486L24.4733 65.2638L25.5177 64.253L27.3469 66.2584H27.4219L30.3492 62.0782L31.5232 62.9386Z"
              fill="var(--brand-color-green-5, #1bb694)"
            />
          </g>
        </g>
        <defs>
          <clipPath
            id="bgblur_0_1886_4384_clip_path"
            transform="translate(10 10)"
          >
            <path d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V92C220 96.4183 216.418 100 212 100H8.00001C3.58173 100 0 96.4183 0 92V8Z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const UIUptimeProgress = ({ inView, elementInView }) => {
  const [progress, setProgress] = useState(0);
  const greenTilesRef = useRef(null);

  useEffect(() => {
    const children = greenTilesRef.current.children;
    const ratio = 100 / (children.length || 1);

    if (!elementInView || !greenTilesRef.current) {
      setProgress(0);
      if (children && children.length > 0) {
        Array.from(children).forEach((child) => {
          child.style.opacity = 0;
        });
      }
      return;
    }

    const interval = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev + 1;
        const tileIndex = Math.floor(newProgress / ratio);
        if (children[tileIndex]) {
          children[tileIndex].style.opacity = 1;
        }
        if (newProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newProgress;
      });
    }, 1);

    return () => clearInterval(interval);
  }, [elementInView]);

  return (
    <div className={cn(styles.uiUptimeProgress, { [styles.inView]: inView })}>
      <div className={cn(styles.uiUptimeProgressCaption)}>
        <div className={cn(styles.uiUptimeProgressCaption__left)}>
          {progress}%
        </div>
        <div className={cn(styles.uiUptimeProgressCaption__right)}>Uptime</div>
      </div>
      <svg
        width="220"
        height="85"
        viewBox="0 0 220 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V77C220 81.4183 216.418 85 212 85H8.00001C3.58173 85 0 81.4183 0 77V8Z"
            fill="#1A3342"
          />
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V77C220 81.4183 216.418 85 212 85H8.00001C3.58173 85 0 81.4183 0 77V8Z"
            fill="#4E6978"
            fillOpacity="0.1"
          />
          <path
            d="M8 0.5H212C216.142 0.5 219.5 3.85787 219.5 8V77C219.5 81.1421 216.142 84.5 212 84.5H8.00001C3.85787 84.5 0.5 81.1421 0.5 77V8C0.5 3.85786 3.85786 0.5 8 0.5Z"
            stroke="#7A8B95"
            strokeOpacity="0.3"
          />
        </g>
        <g fill="#425966">
          <rect x="16" y="47" width="3" height="18" />
          <rect x="21" y="47" width="3" height="18" />
          <rect x="26" y="47" width="3" height="18" />
          <rect x="31" y="47" width="3" height="18" />
          <rect x="36" y="47" width="3" height="18" />
          <rect x="41" y="47" width="3" height="18" />
          <rect x="46" y="47" width="3" height="18" />
          <rect x="51" y="47" width="3" height="18" />
          <rect x="56" y="47" width="3" height="18" />
          <rect x="61" y="47" width="3" height="18" />
          <rect x="66" y="47" width="3" height="18" />
          <rect x="71" y="47" width="3" height="18" />
          <rect x="76" y="47" width="3" height="18" />
          <rect x="81" y="47" width="3" height="18" />
          <rect x="86" y="47" width="3" height="18" />
          <rect x="91" y="47" width="3" height="18" />
          <rect x="96" y="47" width="3" height="18" />
          <rect x="101" y="47" width="3" height="18" />
          <rect x="106" y="47" width="3" height="18" />
          <rect x="111" y="47" width="3" height="18" />
          <rect x="116" y="47" width="3" height="18" />
          <rect x="121" y="47" width="3" height="18" />
          <rect x="126" y="47" width="3" height="18" />
          <rect x="131" y="47" width="3" height="18" />
          <rect x="136" y="47" width="3" height="18" />
          <rect x="141" y="47" width="3" height="18" />
          <rect x="146" y="47" width="3" height="18" />
          <rect x="151" y="47" width="3" height="18" />
          <rect x="156" y="47" width="3" height="18" />
          <rect x="161" y="47" width="3" height="18" />
          <rect x="166" y="47" width="3" height="18" />
          <rect x="171" y="47" width="3" height="18" />
          <rect x="176" y="47" width="3" height="18" />
          <rect x="181" y="47" width="3" height="18" />
          <rect x="186" y="47" width="3" height="18" />
          <rect x="191" y="47" width="3" height="18" />
          <rect x="196" y="47" width="3" height="18" />
          <rect x="201" y="47" width="3" height="18" />
        </g>
        <g
          ref={greenTilesRef}
          className={styles.uiUptimeProgressGreenTiles}
          clipPath="url(#clip1_1888_4625)"
          fill="var(--brand-color-green-5)"
        >
          <rect x="16" y="47" width="3" height="18" />
          <rect x="21" y="47" width="3" height="18" />
          <rect x="26" y="47" width="3" height="18" />
          <rect x="31" y="47" width="3" height="18" />
          <rect x="36" y="47" width="3" height="18" />
          <rect x="41" y="47" width="3" height="18" />
          <rect x="46" y="47" width="3" height="18" />
          <rect x="51" y="47" width="3" height="18" />
          <rect x="56" y="47" width="3" height="18" />
          <rect x="61" y="47" width="3" height="18" />
          <rect x="66" y="47" width="3" height="18" />
          <rect x="71" y="47" width="3" height="18" />
          <rect x="76" y="47" width="3" height="18" />
          <rect x="81" y="47" width="3" height="18" />
          <rect x="86" y="47" width="3" height="18" />
          <rect x="91" y="47" width="3" height="18" />
          <rect x="96" y="47" width="3" height="18" />
          <rect x="101" y="47" width="3" height="18" />
          <rect x="106" y="47" width="3" height="18" />
          <rect x="111" y="47" width="3" height="18" />
          <rect x="116" y="47" width="3" height="18" />
          <rect x="121" y="47" width="3" height="18" />
          <rect x="126" y="47" width="3" height="18" />
          <rect x="131" y="47" width="3" height="18" />
          <rect x="136" y="47" width="3" height="18" />
          <rect x="141" y="47" width="3" height="18" />
          <rect x="146" y="47" width="3" height="18" />
          <rect x="151" y="47" width="3" height="18" />
          <rect x="156" y="47" width="3" height="18" />
          <rect x="161" y="47" width="3" height="18" />
          <rect x="166" y="47" width="3" height="18" />
          <rect x="171" y="47" width="3" height="18" />
          <rect x="176" y="47" width="3" height="18" />
          <rect x="181" y="47" width="3" height="18" />
          <rect x="186" y="47" width="3" height="18" />
          <rect x="191" y="47" width="3" height="18" />
          <rect x="196" y="47" width="3" height="18" />
          <rect x="201" y="47" width="3" height="18" />
        </g>
        <defs>
          <clipPath
            id="bgblur_0_1888_4625_clip_path"
            transform="translate(10 10)"
          >
            <path d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V77C220 81.4183 216.418 85 212 85H8.00001C3.58173 85 0 81.4183 0 77V8Z" />
          </clipPath>
          <clipPath id="clip1_1888_4625">
            <rect
              width="188"
              height="18"
              fill="white"
              transform="translate(16 47)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const UIUptimeProxy = ({ caption, inView, elementInView }) => {
  return (
    <div className={cn(styles.uiUptimeProxy, { [styles.inView]: inView })}>
      <div className={cn(styles.uiUptimeProxyCaption)}>{caption}</div>
      <svg
        width="220"
        height="57"
        viewBox="0 0 220 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V49C220 53.4183 216.418 57 212 57H8.00001C3.58173 57 0 53.4183 0 49V8Z"
            fill="#1A3342"
          />
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V49C220 53.4183 216.418 57 212 57H8.00001C3.58173 57 0 53.4183 0 49V8Z"
            fill="#4E6978"
            fillOpacity="0.1"
          />
          <path
            d="M8 0.5H212C216.142 0.5 219.5 3.85787 219.5 8V49C219.5 53.1421 216.142 56.5 212 56.5H8.00001C3.85787 56.5 0.5 53.1421 0.5 49V8C0.5 3.85786 3.85786 0.5 8 0.5Z"
            stroke="#7A8B95"
            strokeOpacity="0.3"
          />
        </g>
        <rect
          x="204"
          y="38"
          width="32"
          height="18"
          rx="9"
          transform="rotate(-180 204 38)"
          fill="#4E6978"
          fillOpacity="0.3"
        />
        <rect
          x="203.5"
          y="37.5"
          width="31"
          height="17"
          rx="8.5"
          transform="rotate(-180 203.5 37.5)"
          stroke="#7A8B95"
          strokeOpacity="0.3"
        />
        <rect
          x="188"
          y="36"
          width="14"
          height="14"
          rx="7"
          transform="rotate(-180 188 36)"
          fill="#425966"
          className={cn(styles.uiUptimeProxyCircle, {
            [styles.inView]: elementInView,
          })}
        />
        <defs>
          <clipPath
            id="bgblur_0_1888_4720_clip_path"
            transform="translate(10 10)"
          >
            <path d="M0 8C0 3.58172 3.58172 0 8 0H212C216.418 0 220 3.58172 220 8V49C220 53.4183 216.418 57 212 57H8.00001C3.58173 57 0 53.4183 0 49V8Z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const UIUptimeDelivery = forwardRef(
  ({ caption, inView, elementInView }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(styles.uiUptimeDelivery, { [styles.inView]: inView })}
      >
        <div className={styles.uiUptimeDeliveryCaption}>
          <div className={styles.uiUptimeDeliveryCaption__left}>{caption}</div>
          <div className={styles.uiUptimeDeliveryCaption__right}>
            <span>10 GB</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.87553 4.59576L5.79146 9.30127e-05L4.06751 7.18812e-05L4.95671e-07 4.59654L0.748884 5.25924L4.43209 1.09705L4.43209 10.2664L5.43209 10.2664L5.43209 1.10111L9.12805 5.26003L9.87553 4.59576Z"
                  fill="var(--brand-color-green-5, #1bb694)"
                />
              </svg>
            </span>
          </div>
        </div>
        <svg
          width="400"
          height="159"
          viewBox="0 0 400 159"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M0 8C0 3.58173 3.58172 0 8 0H392C396.418 0 400 3.58172 400 8V151C400 155.418 396.418 159 392 159H7.99999C3.58171 159 0 155.418 0 151V8Z"
              fill="#1A3342"
            />
            <path
              d="M0 8C0 3.58173 3.58172 0 8 0H392C396.418 0 400 3.58172 400 8V151C400 155.418 396.418 159 392 159H7.99999C3.58171 159 0 155.418 0 151V8Z"
              fill="#4E6978"
              fillOpacity="0.1"
            />
            <path
              d="M8 0.5H392C396.142 0.5 399.5 3.85786 399.5 8V151C399.5 155.142 396.142 158.5 392 158.5H7.99999C3.85786 158.5 0.5 155.142 0.5 151V8C0.5 3.85787 3.85786 0.5 8 0.5Z"
              stroke="#7A8B95"
              strokeOpacity="0.3"
            />
          </g>
          <g
            className={cn(styles.uiUptimeDeliveryTiles, {
              [styles.inView]: elementInView,
            })}
          >
            <rect x="16" y="88" width="16" height="71" fill="#415765" />
            <rect x="48" y="99" width="16" height="60" fill="#415765" />
            <rect x="80" y="96" width="16" height="63" fill="#415765" />
            <rect x="112" y="77" width="16" height="82" fill="#415765" />
            <rect x="144" y="56" width="16" height="103" fill="#415765" />
            <rect x="176" y="53" width="16" height="106" fill="#415765" />
            <rect x="208" y="75" width="16" height="84" fill="#415765" />
            <rect x="240" y="121" width="16" height="38" fill="#415765" />
            <rect x="304" y="133" width="16" height="26" fill="#415765" />
            <rect x="336" y="119" width="16" height="40" fill="#415765" />
            <rect x="368" y="102" width="16" height="57" fill="#415765" />
            <rect x="272" y="96" width="16" height="63" fill="#415765" />
          </g>
          <defs>
            <clipPath
              id="bgblur_0_1888_4756_clip_path"
              transform="translate(10 10)"
            >
              <path d="M0 8C0 3.58173 3.58172 0 8 0H392C396.418 0 400 3.58172 400 8V151C400 155.418 396.418 159 392 159H7.99999C3.58171 159 0 155.418 0 151V8Z" />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  },
);

UIUptimeDelivery.displayName = 'UIUptimeDelivery';
