import cn from 'classnames';

import TextRenderer from '@components/TextRenderer';

import { BasePrimaryHero } from '../PrimaryHero';
import Form from './Form';

import AnimateIn from '@components/AnimateIn';
import * as styles from './NewHeroHubSpotForm.module.css';
import textStyles from '@styles/textStyles.module.css';
import TickList from '@components/TickList';

export const NewHeroHubSpotForm = ({
  name,
  theme,
  eyebrow,
  heading,
  headingSize = 'xlarge',
  actions,
  backgroundIllustration,
  gradient,
  markerColor,
  text,
  instructions,
  listItems,
  showListUnderHeading,
  showLogosInside,
  form,
  clientLogos,
}) => {
  const textElement = (
    <>
      {text && (
        <TextRenderer
          text={text}
          as="p"
          className={cn(styles.text, textStyles.bodyXL)}
        />
      )}
      {showListUnderHeading && listItems && (
        <TickList className={styles.tickList} items={listItems} />
      )}
    </>
  );

  return (
    <BasePrimaryHero
      name={name}
      eyebrow={eyebrow}
      heading={heading}
      text={textElement}
      actions={actions}
      backgroundIllustration={backgroundIllustration}
      gradient={gradient}
      theme={theme}
      markerColor={markerColor}
      className={styles.hero}
      mediaClassName={styles.formMedia}
      headingSize={headingSize}
      clientLogos={clientLogos}
      showLogosInside={showLogosInside}
    >
      <div className={styles.form}>
        <AnimateIn>
          <Form
            form={form}
            instructions={instructions}
            listItems={showListUnderHeading ? null : listItems}
          />
        </AnimateIn>
      </div>
    </BasePrimaryHero>
  );
};
