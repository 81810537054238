import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { circlesData } from './CirclesData';

import * as styles from './Circles.module.css';

export const phaseDuration = 4;
export const phasesCount = 3;
export const circleTimelineDuration = phaseDuration * phasesCount;

export const createCircleTimeline = (
  circleEl,
  circlePaths,
  circlesLength,
  circleIndex,
  customEase,
  onUICardsAction,
) => {
  const circleTl = gsap.timeline({
    delay: circleIndex * phaseDuration,
    repeat: -1,
    repeatDelay: phaseDuration * (circlesLength - phasesCount),
  });

  if (circlePaths.length > 0 && circlePaths[0].length > 0) {
    gsap.set(circleEl, {
      x: circlePaths[0][0].x,
      y: circlePaths[0][0].y,
      opacity: 0,
    });
  }

  circlePaths.forEach((phasePoints, phaseIndex) => {
    const transitionsCount = phasePoints.length - 1;
    if (transitionsCount <= 0) return;

    const subDuration = phaseDuration / transitionsCount;

    for (let i = 0; i < transitionsCount; i++) {
      const tweenProps = {
        x: phasePoints[i + 1].x,
        y: phasePoints[i + 1].y,
        duration: subDuration,
        ease: 'none',
      };

      if (phaseIndex === 1) {
        tweenProps.ease = customEase;
      } else {
        tweenProps.ease = 'none';
      }

      if (phaseIndex === 2 && i === 0) {
        circleTl.call(() => {
          onUICardsAction({
            active: false,
            cardsStates: [
              { active: false },
              { active: false },
              { active: false },
            ],
          });
        });
      }

      if (phaseIndex === 0 && i === 0) {
        tweenProps.opacity = 1;
      }

      if (phaseIndex === circlePaths.length - 1 && i === transitionsCount - 1) {
        tweenProps.opacity = 0;
      }

      if (phasePoints[i].alert !== undefined) {
        circleTl.call(() => {
          onUICardsAction((prev) => ({
            active: true,
            cardsStates: prev.cardsStates.map((state, index) => ({
              ...state,
              active:
                index === phasePoints[i].alert
                  ? true
                  : prev.cardsStates[index].active,
            })),
          }));
        });
      }

      circleTl.to(circleEl, tweenProps);
    }
  });

  return circleTl;
};

export const createMasterTimeline = (
  circles,
  circlesData,
  customEase,
  onUICardsAction,
) => {
  const masterTl = gsap.timeline();

  circles.forEach((circleEl, index) => {
    const circleTl = createCircleTimeline(
      circleEl,
      circlesData[index],
      circles.length,
      index,
      customEase,
      onUICardsAction,
    );

    masterTl.add(circleTl, 0);
  });

  return masterTl;
};

export const Circles = ({ onUICardsAction }) => {
  const circlesRef = useRef([]);
  const masterTimelineRef = useRef(null);

  useEffect(() => {
    import('gsap/CustomEase').then((module) => {
      const CustomEase = module.default;
      gsap.registerPlugin(CustomEase);
      const customEase = CustomEase.create(
        'custom',
        'M0,0 C0.266,0.101 0.472,0.455 0.496,0.496 0.574,0.63 0.747,0.901 1,1',
      );

      masterTimelineRef.current = createMasterTimeline(
        circlesRef.current,
        circlesData,
        customEase,
        onUICardsAction,
      );
    });

    return () => {
      masterTimelineRef.current?.kill();
    };
  }, [onUICardsAction]);

  return (
    <div className={styles.circles}>
      {circlesData.map((_, i) => (
        <div
          key={i}
          ref={(el) => (circlesRef.current[i] = el)}
          className={styles.circle}
        />
      ))}
    </div>
  );
};
