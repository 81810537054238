import { GridSmall, LeftPathways, RightPathways } from './BackgroundElements';
import { UIScanner } from './UIElements';
import { UIContainer } from './UIContainer';
import { Circles } from './Circles';
import { useInView } from 'framer-motion';
import { useRef, useState } from 'react';

import * as styles from './UICirclesAnimation.module.css';

const UICirclesAnimation = () => {
  const uiContainerRef = useRef(null);
  const uiContainerInView = useInView(uiContainerRef, {
    amount: 0,
    once: true,
  });

  const [uiCardsActions, setUiCardsActions] = useState({
    active: false,
    cardsStates: [{ active: false }, { active: false }, { active: false }],
  });

  return (
    <div className={styles.uiCirclesAnimation}>
      <div className={styles.background}>
        <div className={styles.top}>
          <div className={styles.topVignette}></div>
          <div className={styles.leftVignette}></div>
          <div className={styles.bottomVignette}></div>
          <div className={styles.rightVignette}></div>
        </div>
        <div className={styles.middle}>
          <div className={styles.leftPathways}>
            <LeftPathways />
          </div>
          <div className={styles.rightPathways}>
            <RightPathways />
          </div>
        </div>
        <div className={styles.bottom}>
          <GridSmall />
        </div>
      </div>
      <div className={styles.scene}>
        <UIScanner inView={uiContainerInView} />
        <Circles onUICardsAction={setUiCardsActions} />
        <UIContainer
          ref={uiContainerRef}
          inView={uiContainerInView}
          uiCardsActions={uiCardsActions}
        />
      </div>
    </div>
  );
};

export default UICirclesAnimation;
