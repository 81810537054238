import {
  UIBrowser,
  UIModal,
  UICardOne,
  UICardTwo,
  UICardThree,
  UIUptimeProgress,
  UIUptimeProxy,
  UIUptimeDelivery,
} from './UIElements';
import cn from 'classnames';
import { forwardRef, useRef } from 'react';
import { useInView } from 'framer-motion';

import * as styles from './UIContainer.module.css';

export const UIContainer = forwardRef(({ inView, uiCardsActions }, ref) => {
  const UIUptimeDeliveryRef = useRef(null);
  const elementInView = useInView(UIUptimeDeliveryRef, {
    amount: 0.1,
    once: true,
  });

  return (
    <div ref={ref} className={cn(styles.UIContainer)}>
      <UIBrowser inView={inView} />
      <div className={cn(styles.UIModalContainer)}>
        <UIModal inView={inView} />
        <UICardOne
          inView={inView}
          caption={'Package scanned'}
          uiCardsActions={uiCardsActions}
        />
        <UICardTwo
          inView={inView}
          caption={'Policies evaluated'}
          uiCardsActions={uiCardsActions}
        />
        <UICardThree
          inView={inView}
          caption={'Package cached'}
          uiCardsActions={uiCardsActions}
        />
        <UIUptimeProgress inView={inView} elementInView={elementInView} />
        <UIUptimeProxy
          inView={inView}
          elementInView={elementInView}
          caption={'Proxy and cache'}
        />
        <UIUptimeDelivery
          ref={UIUptimeDeliveryRef}
          inView={inView}
          elementInView={elementInView}
          caption={'Package delivery'}
        />
      </div>
    </div>
  );
});

UIContainer.displayName = 'UIContainer';
