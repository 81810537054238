const phaseInModal = [
  { 
    x: 4,
    y: 325,
  },
  { 
    x: 168, 
    y: 325,
    alert: 0
  },
  { 
    x: 348, 
    y: 325,
    alert: 1
  },
  { 
    x: 528, 
    y: 325,
    alert: 2
  },
  { 
    x: 682, 
    y: 325
  }
];

export const circlesData = [
  // Circle 1
  [
    [
      {
        x: -612, 
        y: -75 
      },
      { 
        x: -532, 
        y: -75 
      },
      { 
        x: -452,
        y: 5
      },
      { 
        x: -372,
        y: 5
      },
      { 
        x: -292,
        y: 5
      },
      { 
        x: -212, 
        y: 5 
      },
      { 
        x: -212, 
        y: 85 
      },
      { 
        x: -132, 
        y: 165
      },
      { 
        x: -132, 
        y: 245
      },
      { 
        x: -52,
        y: 325 
      },
      { 
        x: 4,
        y: 325
      },
    ],
    phaseInModal,
    [
      { 
        x: 682, 
        y: 325
      },
      { 
        x: 748, 
        y: 325
      },
      { 
        x: 748, 
        y: 245,
      },
      { 
        x: 828, 
        y: 165
      },
      { 
        x: 908, 
        y: 85
      },
      { 
        x: 988, 
        y: 5
      },
      { 
        x: 1068, 
        y: -75
      },
      { 
        x: 1148, 
        y: -75
      },
      { 
        x: 1228, 
        y: -75
      },
      { 
        x: 1308, 
        y: -75
      },
    ],
  ],
  // Circle 2
  [
    [
      {
        x: -612, 
        y: 165 
      },
      { 
        x: -532,
        y: 165
      },
      { 
        x: -452,
        y: 85
      },
      { 
        x: -372,
        y: 85
      },
      { 
        x: -292,
        y: 85
      },
      { 
        x: -372,
        y: 165
      },
      { 
        x: -292,
        y: 245
      },
      { 
        x: -212,
        y: 245
      },
      { 
        x: -132,
        y: 245
      },
      { 
        x: -52,
        y: 325
      },
      { 
        x: 4,
        y: 325
      },
    ],
    phaseInModal,
    [
      { 
        x: 682, 
        y: 325
      },
      { 
        x: 748, 
        y: 325
      },
      { 
        x: 748,
        y: 405
      },
      { 
        x: 828,
        y: 405
      },
      { 
        x: 908,
        y: 405
      },
      { 
        x: 988,
        y: 405
      },
      { 
        x: 1068,
        y: 405
      },
      { 
        x: 1148,
        y: 405
      },
      { 
        x: 1228,
        y: 405
      },
      { 
        x: 1308,
        y: 405
      },
    ]
  ],
  // Circle 3
  [
    [
      {
        x: -612, 
        y: 725
      },
      {
        x: -532, 
        y: 725 
      },
      {
        x: -452, 
        y: 725 
      },
      {
        x: -372,
        y: 645 
      },
      {
        x: -372,
        y: 565 
      },
      {
        x: -292,
        y: 565
      },
      {
        x: -292,
        y: 485 
      },
      {
        x: -292,
        y: 405 
      },
      {
        x: -212, 
        y: 405 
      },
      {
        x: -212,  
        y: 325 
      },
      {
        x: -132, 
        y: 325 
      },
      {
        x: -52, 
        y: 325 
      },
      { 
        x: 4,
        y: 325
      }
    ],
    phaseInModal,
    [
      { 
        x: 682, 
        y: 325
      },
      { 
        x: 748, 
        y: 325
      },
      {
        x: 748, 
        y: 405 
      },
      {
        x: 828,
        y: 485
      },
      {
        x: 908,
        y: 565
      },
      {
        x: 988,
        y: 645
      },
      {
        x: 1068,
        y: 725
      },
      {
        x: 1068,
        y: 725
      },
      {
        x: 1148, 
        y: 725
      },
      {
        x: 1228, 
        y: 725
      },
      {
        x: 1308, 
        y: 725
      },
    ]
  ],
  // Circle 4
  [
    [
      {
        x: -612, 
        y: 325
      },
      {
        x: -532, 
        y: 325
      },
      {
        x: -452,
        y: 245
      },
      {
        x: -372,
        y: 165
      },
      {
        x: -372,
        y: 245
      },
      {
        x: -292, 
        y: 245
      },
      {
        x: -212, 
        y: 245
      },
      {
        x: -132, 
        y: 245
      },
      {
        x: -52, 
        y: 325
      },
      {
        x: 4, 
        y: 325
      },
    ],
    phaseInModal,
    [
      { 
        x: 682, 
        y: 325
      },
      { 
        x: 748, 
        y: 325
      },
      { 
        x: 748, 
        y: 245
      },
      { 
        x: 828,
        y: 165
      },
      { 
        x: 908, 
        y: 165
      },
      { 
        x: 988, 
        y: 165
      },
      { 
        x: 1068, 
        y: 165
      },
      { 
        x: 1148, 
        y: 165
      },
      { 
        x: 1228, 
        y: 165
      },
      { 
        x: 1308, 
        y: 165
      },
    ]
  ],
  // Circle 5
  [
    [
      {
        x: -612, 
        y: 85
      },
      {
        x: -532, 
        y: 85
      },
      {
        x: -452, 
        y: 85
      },
      {
        x: -372, 
        y: 85
      },
      {
        x: -292, 
        y: 85
      },
      {
        x: -212, 
        y: 165
      },
      {
        x: -292,
        y: 245
      },
      {
        x: -212,
        y: 245
      },
      {
        x: -132,
        y: 245
      },
      {
        x: -52,
        y: 325
      },
      {
        x: 4,
        y: 325
      },
    ],
    phaseInModal,
    [
      { 
        x: 682, 
        y: 325
      },
      { 
        x: 748, 
        y: 325
      },
      {
        x: 748, 
        y: 405
      },
      {
        x: 828,
        y: 485
      },
      {
        x: 908,
        y: 565
      },
      {
        x: 988,
        y: 565
      },
      {
        x: 1068,
        y: 565
      },
      {
        x: 1148,
        y: 565
      },
      {
        x: 1228,
        y: 565
      },
      {
        x: 1308,
        y: 565
      }
    ]
  ],
  // Circle 6
  [
    [
      {
        x: -612, 
        y: 485
      },
      {
        x: -532, 
        y: 485
      },
      {
        x: -452,
        y: 405
      },
      {
        x: -372,
        y: 485
      },
      {
        x: -292, 
        y: 485
      },
      {
        x: -212, 
        y: 485
      },
      {
        x: -132, 
        y: 485
      },
      {
        x: -52, 
        y: 485
      },
      {
        x: -132,
        y: 405
      },
      {
        x: -52,
        y: 325
      },
      {
        x: 4,
        y: 325
      },
    ],
    phaseInModal,
    [
      { 
        x: 682, 
        y: 325
      },
      { 
        x: 748, 
        y: 325
      },
      {
        x: 748, 
        y: 405
      },
      {
        x: 828,
        y: 485
      },
      {
        x: 908,
        y: 565
      },
      {
        x: 988,
        y: 565
      },
      {
        x: 1068,
        y: 565
      },
      {
        x: 1148,
        y: 565
      },
      {
        x: 1228,
        y: 565
      },
      {
        x: 1308,
        y: 565
      }
    ]
  ]
];