import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

export const Packages = () => {
  const svgRef = useRef(null);
  const svgInView = useInView(svgRef, { once: true });

  return (
    <svg
      ref={svgRef}
      width="1389"
      height="480"
      viewBox="0 0 1389 480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="translate(280, 125)"
      style={{
        overflow: 'visible',
      }}
    >
      <motion.rect
        x="0.5"
        y="119.5"
        width="40"
        height="40"
        stroke="var(--brand-color-red-6)"
        initial={{ opacity: 0, y: 160 }}
        animate={
          svgInView && {
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: 'easeOut' },
          }
        }
      />
      <motion.rect
        x="1080.5"
        y="439.5"
        width="40"
        height="40"
        stroke="var(--brand-color-red-6)"
        initial={{ opacity: 0, x: 160 }}
        animate={
          svgInView && {
            opacity: 1,
            x: 0,
            transition: { duration: 1, ease: 'easeOut' },
          }
        }
      />
      <motion.rect
        x="1340.57"
        y="99.284"
        width="40"
        height="40"
        fill="var(--brand-color-red-6)"
        initial={{ opacity: 0, rotate: 90 }}
        animate={
          svgInView && {
            opacity: 0.75,
            rotate: 45,
            transition: { duration: 1, ease: 'easeOut' },
          }
        }
        transformBox="fill-box"
        transformOrigin="center"
      />
      <motion.rect
        opacity="0.25"
        x="60.2843"
        y="259"
        width="40"
        height="40"
        fill="var(--brand-color-red-6)"
        initial={{ opacity: 0, rotate: 45 }}
        animate={
          svgInView && {
            opacity: 0.25,
            rotate: 135,
            transition: { duration: 1, ease: 'easeOut' },
          }
        }
        transformBox="fill-box"
        transformOrigin="center"
      />
      <motion.rect
        opacity="0.25"
        y="39.5"
        height="40"
        stroke="var(--brand-color-red-6)"
        strokeDasharray="2 2"
        initial={{ opacity: 0, width: 80, x: 1120.5 }}
        animate={
          svgInView && {
            opacity: 1,
            width: 200,
            x: 1000.5,
            transition: { duration: 1, ease: 'easeOut' },
          }
        }
        transformBox="fill-box"
        transformOrigin="right"
      />

      <motion.rect
        x="160.5"
        y="399.5"
        height="40"
        stroke="var(--brand-color-red-6)"
        strokeDasharray="2 2"
        initial={{ opacity: 0, width: 80 }}
        animate={
          svgInView && {
            opacity: 1,
            width: 200,
            transition: { duration: 1, ease: 'easeOut' },
          }
        }
      />
      <motion.circle
        opacity="0.25"
        cx="1220.5"
        cy="259.5"
        r="19.5"
        fill="var(--brand-color-red-6)"
        initial={{ opacity: 0, y: 160 }}
        animate={
          svgInView && {
            opacity: 0.25,
            y: 0,
            transition: { duration: 1, ease: 'easeOut' },
          }
        }
      />
      <motion.circle
        opacity="0.75"
        cx="341"
        cy="20"
        r="20"
        fill="var(--brand-color-red-6)"
        initial={{ opacity: 0, y: -160 }}
        animate={
          svgInView && {
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: 'easeOut' },
          }
        }
      />
    </svg>
  );
};
